@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html, body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1600px;
  min-width: 1000px;
  margin: 0 auto;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipeIn {
  animation-name: swipeIn;
  -webkit-animation-name: swipeIn;
}

@keyframes swipeIn {
  0% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes swipeIn {
  0% {
    -webkit-transform: translateX(50%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}

.swipeInLeft {
  animation-name: swipeInLeft;
  -webkit-animation-name: swipeInLeft;
}

@keyframes swipeInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes swipeInLeft {
  0% {
    -webkit-transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}

.dashboard-scroll-style::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

.dashboard-scroll-style::-webkit-scrollbar-thumb {
  border-radius: 6px;
  @apply bg-gray-300
}

.dashboard-scroll-style::-webkit-scrollbar-track {
  border-radius: 6px;
  @apply bg-gray-100;
}

/* Let's highlight canvas boundaries */
#gjs {
  border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.css-b62m3t-container {
  width:100%;
}

 .css-b62m3t-container .css-13cymwt-control {
border:1px solid black;
}

.css-b62m3t-container .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
  text-align:start;
}
.css-t3ipsp-control:hover {
  @apply border-gray-300
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

.quill {
  width: 100%;
  /* border-radius: 8px; */
  margin-bottom: 3rem;
}

.quill .ql-editor {
  height: 100%;
}

.quill>.ql-container>.ql-editor.ql-blank::before {
  color: #d4d4d4;
}

.ql-container.ql-snow {
  border: 1px solid #f3f3f3 !important;
  height: 300px;
  border-top: none;
  font-size: 14px;
  border-radius: 0px 0px 8px 8px;
}

.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #f3f3f3 !important;
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-size: 14px;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.list {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipeUp {
  animation-name: swipeUp;
  -webkit-animation-name: swipeUp;
}

@keyframes swipeUp {
  0% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes swipeUp {
  0% {
    -webkit-transform: translateY(50%);
  }

  100% {
    -webkit-transform: translateY(0px);
  }
}

.txt {
  min-block-size: 1em;
  min-height: 1em;
  min-width: 3ch;
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
  word-spacing: .05em !important;
  inline-size: 100%;
  letter-spacing: .05em;
}


@tailwind base;
@tailwind components;
@tailwind utilities;